import React from "react";
import { useContracts } from "../hooks/hooks";
import * as c from "../constants";
import * as utils from "../utils";
import Web3 from "web3";
import { initContractsPartial, azimuth } from "azimuth-js";
import { toast } from "react-toastify";

export const galaxyBase = [
  {
    point: 178,
    patp: "~pem",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 188, patp: "~byt", dns_registered: false, dns_country: "" },
  { point: 170, patp: "~lep", dns_registered: false, dns_country: "" },
  { point: 151, patp: "~ryx", dns_registered: true, dns_country: "Germany" },
  {
    point: 160,
    patp: "~ten",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 101,
    patp: "~wet",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 64,
    patp: "~rys",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 74, patp: "~sup", dns_registered: false, dns_country: "" },
  {
    point: 172,
    patp: "~ber",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 90, patp: "~fus", dns_registered: false, dns_country: "" },
  { point: 205, patp: "~lyd", dns_registered: false, dns_country: "" },
  { point: 169, patp: "~fyl", dns_registered: false, dns_country: "" },
  { point: 29, patp: "~tyv", dns_registered: true, dns_country: "Singapore" },
  {
    point: 0,
    patp: "~zod",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 60, patp: "~bur", dns_registered: false, dns_country: "" },
  { point: 120, patp: "~fel", dns_registered: false, dns_country: "" },
  {
    point: 180,
    patp: "~lur",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 244, patp: "~byr", dns_registered: false, dns_country: "" },
  { point: 27, patp: "~hec", dns_registered: true, dns_country: "Finland" },
  { point: 240, patp: "~lyr", dns_registered: false, dns_country: "" },
  { point: 241, patp: "~tes", dns_registered: false, dns_country: "" },
  { point: 242, patp: "~mud", dns_registered: false, dns_country: "" },
  { point: 243, patp: "~nyt", dns_registered: false, dns_country: "" },
  { point: 245, patp: "~sen", dns_registered: false, dns_country: "" },
  { point: 246, patp: "~weg", dns_registered: false, dns_country: "" },
  { point: 1, patp: "~nec", dns_registered: false, dns_country: "" },
  { point: 2, patp: "~bud", dns_registered: false, dns_country: "" },
  { point: 3, patp: "~wes", dns_registered: false, dns_country: "" },
  { point: 4, patp: "~sev", dns_registered: false, dns_country: "" },
  { point: 5, patp: "~per", dns_registered: false, dns_country: "" },
  { point: 6, patp: "~sut", dns_registered: false, dns_country: "" },
  { point: 7, patp: "~let", dns_registered: false, dns_country: "" },
  { point: 8, patp: "~ful", dns_registered: false, dns_country: "" },
  { point: 9, patp: "~pen", dns_registered: false, dns_country: "" },
  { point: 10, patp: "~syt", dns_registered: false, dns_country: "" },
  { point: 11, patp: "~dur", dns_registered: false, dns_country: "" },
  { point: 12, patp: "~wep", dns_registered: false, dns_country: "" },
  { point: 13, patp: "~ser", dns_registered: false, dns_country: "" },
  { point: 14, patp: "~wyl", dns_registered: false, dns_country: "" },
  { point: 15, patp: "~sun", dns_registered: false, dns_country: "" },
  { point: 16, patp: "~ryp", dns_registered: false, dns_country: "" },
  { point: 17, patp: "~syx", dns_registered: false, dns_country: "" },
  { point: 18, patp: "~dyr", dns_registered: false, dns_country: "" },
  { point: 19, patp: "~nup", dns_registered: false, dns_country: "" },
  { point: 20, patp: "~heb", dns_registered: false, dns_country: "" },
  { point: 21, patp: "~peg", dns_registered: false, dns_country: "" },
  { point: 22, patp: "~lup", dns_registered: false, dns_country: "" },
  { point: 23, patp: "~dep", dns_registered: false, dns_country: "" },
  { point: 25, patp: "~put", dns_registered: false, dns_country: "" },
  { point: 26, patp: "~lug", dns_registered: false, dns_country: "" },
  {
    point: 28,
    patp: "~ryt",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 30, patp: "~syd", dns_registered: false, dns_country: "" },
  { point: 31, patp: "~nex", dns_registered: false, dns_country: "" },
  {
    point: 247,
    patp: "~fyr",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 248, patp: "~mur", dns_registered: false, dns_country: "" },
  { point: 32, patp: "~lun", dns_registered: false, dns_country: "" },
  { point: 33, patp: "~mep", dns_registered: false, dns_country: "" },
  { point: 34, patp: "~lut", dns_registered: false, dns_country: "" },
  { point: 35, patp: "~sep", dns_registered: false, dns_country: "" },
  { point: 36, patp: "~pes", dns_registered: false, dns_country: "" },
  {
    point: 37,
    patp: "~del",
    dns_registered: true,
    dns_country: "Netherlands",
  },
  { point: 38, patp: "~sul", dns_registered: false, dns_country: "" },
  { point: 40, patp: "~tem", dns_registered: false, dns_country: "" },
  { point: 41, patp: "~led", dns_registered: false, dns_country: "" },
  { point: 42, patp: "~tul", dns_registered: false, dns_country: "" },
  { point: 43, patp: "~met", dns_registered: true, dns_country: "Australia" },
  {
    point: 44,
    patp: "~wen",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 45, patp: "~byn", dns_registered: false, dns_country: "" },
  { point: 46, patp: "~hex", dns_registered: false, dns_country: "" },
  { point: 48, patp: "~pyl", dns_registered: false, dns_country: "" },
  { point: 49, patp: "~dul", dns_registered: false, dns_country: "" },
  { point: 50, patp: "~het", dns_registered: false, dns_country: "" },
  { point: 51, patp: "~mev", dns_registered: true, dns_country: "Japan" },
  {
    point: 52,
    patp: "~rut",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 53, patp: "~tyl", dns_registered: false, dns_country: "" },
  { point: 54, patp: "~wyd", dns_registered: false, dns_country: "" },
  { point: 55, patp: "~tep", dns_registered: false, dns_country: "" },
  { point: 56, patp: "~bes", dns_registered: false, dns_country: "" },
  { point: 57, patp: "~dex", dns_registered: false, dns_country: "" },
  { point: 58, patp: "~sef", dns_registered: false, dns_country: "" },
  { point: 59, patp: "~wyc", dns_registered: false, dns_country: "" },
  { point: 61, patp: "~der", dns_registered: false, dns_country: "" },
  { point: 62, patp: "~nep", dns_registered: false, dns_country: "" },
  { point: 63, patp: "~pur", dns_registered: false, dns_country: "" },
  { point: 65, patp: "~reb", dns_registered: false, dns_country: "" },
  { point: 66, patp: "~den", dns_registered: false, dns_country: "" },
  { point: 67, patp: "~nut", dns_registered: true, dns_country: "Canada" },
  {
    point: 68,
    patp: "~sub",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 69,
    patp: "~pet",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 70,
    patp: "~rul",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 71, patp: "~syn", dns_registered: false, dns_country: "" },
  {
    point: 73,
    patp: "~tyd",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 75, patp: "~sem", dns_registered: false, dns_country: "" },
  { point: 76, patp: "~wyn", dns_registered: false, dns_country: "" },
  { point: 77, patp: "~rec", dns_registered: false, dns_country: "" },
  { point: 78, patp: "~meg", dns_registered: false, dns_country: "" },
  {
    point: 79,
    patp: "~net",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 81, patp: "~mul", dns_registered: false, dns_country: "" },
  {
    point: 82,
    patp: "~nym",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 83, patp: "~tev", dns_registered: false, dns_country: "" },
  { point: 84, patp: "~web", dns_registered: false, dns_country: "" },
  {
    point: 85,
    patp: "~sum",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 86, patp: "~mut", dns_registered: false, dns_country: "" },
  { point: 87, patp: "~nyx", dns_registered: false, dns_country: "" },
  { point: 88, patp: "~rex", dns_registered: false, dns_country: "" },
  { point: 91, patp: "~hep", dns_registered: false, dns_country: "" },
  { point: 24, patp: "~dys", dns_registered: true, dns_country: "Germany" },
  {
    point: 39,
    patp: "~ped",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 80, patp: "~sec", dns_registered: false, dns_country: "" },
  { point: 92, patp: "~ben", dns_registered: false, dns_country: "" },
  { point: 93, patp: "~mus", dns_registered: false, dns_country: "" },
  { point: 94, patp: "~wyx", dns_registered: false, dns_country: "" },
  { point: 95, patp: "~sym", dns_registered: false, dns_country: "" },
  { point: 96, patp: "~sel", dns_registered: false, dns_country: "" },
  { point: 97, patp: "~ruc", dns_registered: false, dns_country: "" },
  { point: 98, patp: "~dec", dns_registered: false, dns_country: "" },
  { point: 103, patp: "~myn", dns_registered: false, dns_country: "" },
  { point: 104, patp: "~mes", dns_registered: false, dns_country: "" },
  { point: 106, patp: "~bet", dns_registered: false, dns_country: "" },
  { point: 108, patp: "~tux", dns_registered: false, dns_country: "" },
  { point: 110, patp: "~myr", dns_registered: false, dns_country: "" },
  { point: 111, patp: "~pel", dns_registered: false, dns_country: "" },
  { point: 112, patp: "~syp", dns_registered: false, dns_country: "" },
  { point: 113, patp: "~ter", dns_registered: false, dns_country: "" },
  { point: 114, patp: "~meb", dns_registered: false, dns_country: "" },
  { point: 115, patp: "~set", dns_registered: false, dns_country: "" },
  { point: 116, patp: "~dut", dns_registered: false, dns_country: "" },
  { point: 118, patp: "~tex", dns_registered: false, dns_country: "" },
  { point: 119, patp: "~sur", dns_registered: false, dns_country: "" },
  { point: 89, patp: "~teb", dns_registered: false, dns_country: "" },
  { point: 121, patp: "~tud", dns_registered: false, dns_country: "" },
  { point: 122, patp: "~nux", dns_registered: false, dns_country: "" },
  { point: 123, patp: "~rux", dns_registered: false, dns_country: "" },
  { point: 124, patp: "~ren", dns_registered: false, dns_country: "" },
  { point: 125, patp: "~wyt", dns_registered: false, dns_country: "" },
  { point: 126, patp: "~nub", dns_registered: false, dns_country: "" },
  { point: 127, patp: "~med", dns_registered: false, dns_country: "" },
  { point: 128, patp: "~lyt", dns_registered: false, dns_country: "" },
  { point: 129, patp: "~dus", dns_registered: false, dns_country: "" },
  { point: 130, patp: "~neb", dns_registered: false, dns_country: "" },
  { point: 131, patp: "~rum", dns_registered: false, dns_country: "" },
  { point: 132, patp: "~tyn", dns_registered: false, dns_country: "" },
  { point: 133, patp: "~seg", dns_registered: false, dns_country: "" },
  { point: 134, patp: "~lyx", dns_registered: false, dns_country: "" },
  { point: 135, patp: "~pun", dns_registered: false, dns_country: "" },
  { point: 136, patp: "~res", dns_registered: false, dns_country: "" },
  { point: 137, patp: "~red", dns_registered: false, dns_country: "" },
  { point: 138, patp: "~fun", dns_registered: false, dns_country: "" },
  { point: 139, patp: "~rev", dns_registered: false, dns_country: "" },
  {
    point: 140,
    patp: "~ref",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 141, patp: "~mec", dns_registered: false, dns_country: "" },
  { point: 142, patp: "~ted", dns_registered: false, dns_country: "" },
  {
    point: 145,
    patp: "~leb",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 146, patp: "~dux", dns_registered: false, dns_country: "" },
  { point: 147, patp: "~ryn", dns_registered: false, dns_country: "" },
  { point: 148, patp: "~num", dns_registered: false, dns_country: "" },
  { point: 105, patp: "~det", dns_registered: false, dns_country: "" },
  {
    point: 144,
    patp: "~bex",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 107,
    patp: "~bel",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 109,
    patp: "~tug",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 99,
    patp: "~wex",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 117,
    patp: "~deg",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 102, patp: "~dyl", dns_registered: false, dns_country: "" },
  { point: 100, patp: "~syr", dns_registered: false, dns_country: "" },
  {
    point: 149,
    patp: "~pyx",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 150, patp: "~ryg", dns_registered: false, dns_country: "" },
  {
    point: 153,
    patp: "~tyr",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 154, patp: "~tus", dns_registered: false, dns_country: "" },
  { point: 155, patp: "~tyc", dns_registered: false, dns_country: "" },
  { point: 156, patp: "~leg", dns_registered: false, dns_country: "" },
  {
    point: 157,
    patp: "~nem",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 158, patp: "~fer", dns_registered: false, dns_country: "" },
  { point: 159, patp: "~mer", dns_registered: false, dns_country: "" },
  { point: 161, patp: "~lus", dns_registered: false, dns_country: "" },
  { point: 163, patp: "~syl", dns_registered: false, dns_country: "" },
  { point: 164, patp: "~tec", dns_registered: false, dns_country: "" },
  { point: 165, patp: "~mex", dns_registered: false, dns_country: "" },
  { point: 167, patp: "~rym", dns_registered: false, dns_country: "" },
  { point: 168, patp: "~tuc", dns_registered: false, dns_country: "" },
  { point: 174, patp: "~hut", dns_registered: false, dns_country: "" },
  { point: 175, patp: "~tun", dns_registered: false, dns_country: "" },
  { point: 173, patp: "~mug", dns_registered: false, dns_country: "" },
  { point: 183, patp: "~bep", dns_registered: false, dns_country: "" },
  { point: 184, patp: "~run", dns_registered: false, dns_country: "" },
  { point: 185, patp: "~mel", dns_registered: false, dns_country: "" },
  { point: 186, patp: "~pex", dns_registered: false, dns_country: "" },
  { point: 189, patp: "~typ", dns_registered: false, dns_country: "" },
  { point: 191, patp: "~myl", dns_registered: false, dns_country: "" },
  { point: 192, patp: "~wed", dns_registered: false, dns_country: "" },
  { point: 193, patp: "~duc", dns_registered: false, dns_country: "" },
  { point: 194, patp: "~fur", dns_registered: false, dns_country: "" },
  { point: 195, patp: "~fex", dns_registered: false, dns_country: "" },
  { point: 196, patp: "~nul", dns_registered: false, dns_country: "" },
  { point: 197, patp: "~luc", dns_registered: false, dns_country: "" },
  { point: 198, patp: "~len", dns_registered: false, dns_country: "" },
  { point: 199, patp: "~ner", dns_registered: false, dns_country: "" },
  { point: 200, patp: "~lex", dns_registered: false, dns_country: "" },
  { point: 202, patp: "~ned", dns_registered: false, dns_country: "" },
  { point: 203, patp: "~lec", dns_registered: false, dns_country: "" },
  { point: 204, patp: "~ryd", dns_registered: false, dns_country: "" },
  { point: 207, patp: "~wel", dns_registered: false, dns_country: "" },
  { point: 208, patp: "~nyd", dns_registered: false, dns_country: "" },
  {
    point: 162,
    patp: "~nus",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 177,
    patp: "~sud",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 181,
    patp: "~def",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 152, patp: "~fep", dns_registered: false, dns_country: "" },
  {
    point: 166,
    patp: "~pub",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 206, patp: "~fen", dns_registered: false, dns_country: "" },
  {
    point: 182,
    patp: "~bus",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 171, patp: "~deb", dns_registered: false, dns_country: "" },
  { point: 143, patp: "~rus", dns_registered: true, dns_country: "Finland" },
  { point: 209, patp: "~hus", dns_registered: false, dns_country: "" },
  {
    point: 179,
    patp: "~dev",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 213, patp: "~hes", dns_registered: false, dns_country: "" },
  {
    point: 214,
    patp: "~fet",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 215, patp: "~des", dns_registered: false, dns_country: "" },
  { point: 216, patp: "~ret", dns_registered: false, dns_country: "" },
  { point: 219, patp: "~nyr", dns_registered: false, dns_country: "" },
  { point: 220, patp: "~seb", dns_registered: false, dns_country: "" },
  { point: 222, patp: "~ryl", dns_registered: false, dns_country: "" },
  { point: 223, patp: "~lud", dns_registered: false, dns_country: "" },
  { point: 224, patp: "~rem", dns_registered: false, dns_country: "" },
  { point: 225, patp: "~lys", dns_registered: false, dns_country: "" },
  { point: 226, patp: "~fyn", dns_registered: false, dns_country: "" },
  { point: 227, patp: "~wer", dns_registered: false, dns_country: "" },
  { point: 228, patp: "~ryc", dns_registered: false, dns_country: "" },
  { point: 229, patp: "~sug", dns_registered: false, dns_country: "" },
  { point: 230, patp: "~nys", dns_registered: false, dns_country: "" },
  { point: 231, patp: "~nyl", dns_registered: false, dns_country: "" },
  { point: 232, patp: "~lyn", dns_registered: false, dns_country: "" },
  { point: 233, patp: "~dyn", dns_registered: false, dns_country: "" },
  { point: 235, patp: "~lux", dns_registered: false, dns_country: "" },
  {
    point: 236,
    patp: "~fed",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 237, patp: "~sed", dns_registered: false, dns_country: "" },
  { point: 239, patp: "~mun", dns_registered: false, dns_country: "" },
  { point: 249, patp: "~tel", dns_registered: false, dns_country: "" },
  {
    point: 250,
    patp: "~rep",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 251, patp: "~teg", dns_registered: false, dns_country: "" },
  { point: 252, patp: "~pec", dns_registered: false, dns_country: "" },
  { point: 253, patp: "~nel", dns_registered: false, dns_country: "" },
  { point: 254, patp: "~nev", dns_registered: false, dns_country: "" },
  { point: 255, patp: "~fes", dns_registered: false, dns_country: "" },
  { point: 176, patp: "~byl", dns_registered: false, dns_country: "" },
  {
    point: 210,
    patp: "~rel",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 217, patp: "~dun", dns_registered: false, dns_country: "" },
  { point: 72, patp: "~reg", dns_registered: false, dns_country: "" },
  { point: 218, patp: "~ler", dns_registered: false, dns_country: "" },
  {
    point: 211,
    patp: "~rud",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 47,
    patp: "~feb",
    dns_registered: true,
    dns_country: "United States",
  },
  {
    point: 234,
    patp: "~dem",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 221, patp: "~hul", dns_registered: false, dns_country: "" },
  { point: 190, patp: "~lev", dns_registered: false, dns_country: "" },
  { point: 201, patp: "~rup", dns_registered: false, dns_country: "" },
  { point: 187, patp: "~dyt", dns_registered: false, dns_country: "" },
  { point: 238, patp: "~bec", dns_registered: false, dns_country: "" },
  {
    point: 212,
    patp: "~nes",
    dns_registered: true,
    dns_country: "United States",
  },
];

export const refreshGalaxyData = async (data) => {
  const apiUrl = `${c.API_URL}/refresh_galaxy_data`;
  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const syncGalaxies = async () => {
  const provider = new Web3.providers.HttpProvider(c.INFURA_URL);
  const web3 = new Web3(provider);

  const contracts = await initContractsPartial(web3, c.CONTRACT.azimuth);

  const gxs = galaxyBase;

  //   get the current state of the LSR, CSR, and WSTR contracts

  const LSRlist =
    contracts &&
    (await azimuth.getOwnedPoints(contracts, c.CONTRACT.linearStarRelease));

  const CSRlist =
    contracts &&
    (await azimuth.getOwnedPoints(
      contracts,
      c.CONTRACT.conditionalStarRelease
    ));

  const WSTRlist =
    contracts &&
    (await azimuth.getOwnedPoints(contracts, c.CONTRACT.wstrTreasury));

  // break down those above contracts into individual galaxies i think?
  let lsr = [];
  let csr = [];
  let wstr = [];
  let gxyArray = [];

  let res1 = [];
  let res2 = [];
  let res3 = [];

  await Promise.all([
    (res1 = LSRlist && (await utils.getGalaxiesFromStars(LSRlist))),
    (res2 = CSRlist && (await utils.getGalaxiesFromStars(CSRlist))),
    (res3 = WSTRlist && (await utils.getGalaxiesFromStars(WSTRlist))),
  ]).then((res) => {
    console.log(res);
    lsr = res[0];
    csr = res[1];
    wstr = res[2];
  });

  lsr &&
    csr &&
    wstr &&
    (await Promise.all(
      gxs.map(async (g, key) => {
        const spawnCnt = await azimuth.getSpawnCount(contracts, g.point);
        const lockLSR = lsr.filter((l) => l.galaxy == g.patp).length;
        const lockCSR = csr.filter((l) => l.galaxy == g.patp).length;
        gxyArray.push({
          dns_registered: g.dns_registered,
          dns_country: g.dns_country,
          point: g.point,
          patp: g.patp,
          unbooted: g.unbooted,
          address: await azimuth.getOwner(contracts, g.point),
          locked_conditional: lockCSR,
          locked_linear: lockLSR,
          wstr: wstr.filter((l) => l.galaxy == g.patp).length,
          sponsoring: await azimuth.getSponsoringCount(contracts, g.point),
          spawned: spawnCnt,
          unspawned: 255 - spawnCnt,
          unlocked: spawnCnt - (lockCSR + lockLSR),
        });
      })
    ));

  // let gxyArray = [];

  // contracts &&
  //   lsr &&
  //   csr &&
  //   wstr &&
  //   (await Promise.all(
  //     gxs.map(async (g, key) => {
  //       const spawnCnt = await azimuth.getSpawnCount(contracts, g.point);
  //       const lockLSR = lsr.filter((l) => l.galaxy == g.patp).length;
  //       const lockCSR = csr.filter((l) => l.galaxy == g.patp).length;
  //       gxyArray.push({
  //         dns_registered: g.dns_registered,
  //         dns_country: g.dns_country,
  //         point: g.point,
  //         patp: g.patp,
  //         unbooted: g.unbooted,
  //         address: await azimuth.getOwner(contracts, g.point),
  //         locked_conditional: lockCSR,
  //         locked_linear: lockLSR,
  //         wstr: wstr.filter((l) => l.galaxy == g.patp).length,
  //         sponsoring: await azimuth.getSponsoringCount(contracts, g.point),
  //         spawned: spawnCnt,
  //         unspawned: 255 - spawnCnt,
  //         unlocked: spawnCnt - (lockCSR + lockLSR),
  //       });
  //     })
  //   ));

  // gxyArray.length >= 255 && console.log(gxyArray);

  gxyArray.length >= 255 && refreshGalaxyData(gxyArray);

  if (gxyArray.length >= 255) {
    return "success";
  } else {
    return "error";
  }
};

const mock = [
  {
    point: 178,
    patp: "~pem",
    dns_registered: true,
    dns_country: "United States",
  },
  { point: 188, patp: "~byt", dns_registered: false, dns_country: "" },
  { point: 170, patp: "~lep", dns_registered: false, dns_country: "" },
  { point: 151, patp: "~ryx", dns_registered: true, dns_country: "Germany" },
];

export const getAzimuthState = async () => {
  const results = await Promise.all(
    galaxyBase.map(async (g) => {
      const sponsored = await utils.getSponsored_L2(g.patp);
      return {
        point: g.point,
        patp: g.patp,
        sponsored: sponsored.residents,
      };
    })
  );

  return results;
};
